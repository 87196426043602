<template lang="pug">
	div
		loading(:active.sync="busy", :is-full-page="true")
		.widget(v-if="USER")
			.widget-header
				h1.title Payments
			.widget-body
				//form.form.form-inline.ov-filters(@submit.prevent="loadData()", :class="{expanded:filtersExpanded}")
					a.toggle-filters(href="javascript: void(0);", @click="toggleFilters()")
						span Filters
						i.la.la-chevron-up
					.form-row-main
						div
							//.form-group
								label Search
								input.form-control(type="text", placeholder="Keyword...", v-model="filters.keyword")
							.form-group
								label Status
								multiselect(:multiple="true", :options="statusOptions", v-model="filters.status",
									track-by="v", label="t", deselect-label="", select-label="")
							.form-group.no-label
								button.btn.btn-primary(type="submit") Go
				//p.clearfix &nbsp;

				.ov-table-wrapper
					table.table.table-bordered.table-offer-list
						thead
							tr
								th Month / Year
								th Status
								th Confirmed
								th Total (VAT)
								th Files
								th Paid On
						tbody
							tr(v-if="!records.length")
								td(colspan="6") No matching records were found
							tr(v-for="r in records")
								td
									span(style="margin: 1rem; font-size:14px") {{ r.month }} / {{ r.year }}
								td
									button.btn.badge.badge-pill.badge-danger(v-if="r.status===1", type="button") Open
									button.btn.badge.badge-pill.badge-info(v-if="r.status===2 || r.status===3", type="button") Paid
									// button.btn.badge.badge-pill.badge-primary(v-if="r.status===4", type="button") Postponed
								td
									div {{ r.confirmed_usd | toCurrency }}
										i.la.ml-1(v-if="r.o_confirmed_usd", :class="{'la-balance-scale-right': r.confirmed_usd <= r.o_confirmed_usd, 'la-balance-scale-left': r.confirmed_usd > r.o_confirmed_usd}", v-b-tooltip.hover, :title="'Offset $'+r.o_confirmed_usd")

								td(style="font-weight: bold")
									div {{ r.total_usd | toCurrency }}
								td
									span(v-for="f in r.files")
										a(:href="f.full_path", target="_blank", v-b-tooltip, :title="f.name")
											i.la.la-file
									.add-file(:class="{'sm-btn': r.files.length > 0}")
										i.la(:class="{'la-plus': r.files.length > 0, 'la-upload': r.files.length === 0}")
										input(type="file", :ref="'file-'+r.identifier", v-on:change="handleSelectFile(r)")
								td {{ r.date_paid }}

						// tfoot(v-if="summary")
							tr
								th(colspan="2") &nbsp;
								th {{ summary.confirmed_usd | toCurrency }}
								th {{ summary.total_usd | toCurrency }}
								th(colspan="2") &nbsp;

</template>
<script>

export default {
	name: 'PublisherPayments',
	components: {

	},
	computed: {
		USER(){
			return this.$store.state.user;
		}
	},
	data(){
		return {
			busy: false,
			filters: {
				status: [],
				year: '',
				month: ''
			},
			records: [],
			summary: null,
			statusOptions: [
				{v: 1, t: 'open'},
				{v: 2, t: 'paid'},
				{v: 3, t: 'closed'},
				{v: 4, t: 'postponed'},
			],
		};
	},
	methods: {
		async loadData(doExport){
			this.busy = true;
			let params = {
				do_export: doExport ? doExport : 0,
				status: this.filters.status.map(r => r.v).join(','),
				year: this.filters.year,
				month: this.filters.month
			};
			try {
				let resp = await this.$ovReq.get('publishers/finance/getList', {params});
				if (doExport === 1) {
					window.open(resp.path);
				} else {
					this.summary = resp.summary;
					this.records = resp.records;
				}
			} catch (e) {
				console.error(e);
			}
			this.busy = false;
		},

		handleSelectFile(r){
			let k = 'file-'+r.identifier;
			if (this.$refs[k] && this.$refs[k][0] && this.$refs[k][0].files && this.$refs[k][0].files.length > 0) {
				let droppedFiles = this.$refs[k][0].files;
				this.uploadFile(droppedFiles[0], r);
			}
		},
		async uploadFile(f, record){
			let formData = new FormData();
			formData.append('file', f);

			try {
				this.busy = true;
				await this.$http.post('publishers/finance/upload/'+record.identifier, formData, {
					headers: {
						'Content-Type': 'multipart/form-data'
					}
				});
				this.busy = false;
				await this.loadData();
			} catch (e) {
				console.error(e);
			}
			this.busy = false;
		},
	},
	mounted(){
		this.loadData();
	}
}
</script>
